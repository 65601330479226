import { getRequest, postRequest } from "@/helpers/requests";
import { Answer } from "@/helpers/objects/common";
import { mapRecipient } from "@/helpers/objects/mapper";

import store from "@/plugins/vuex/index";

const state = () => ({
  recipient: null,
  cachedRecipientAnswers: null,
  rfiSummary: null,
  activeAnswer: new Answer(),
  isReview: false,
  currentQuestionIndex: -1,
  isLoadingData: false,
  isSubmitting: false,
  responseId: null,
  allRfiResponses: [],
  tableFilters: {
    page: 1,
    itemsPerPage: 25,
    sortBy: null,
    sortDesc: false,
    chartererId: null,
  },
  tableMetaData: {
    totalItemCount: 0,
    status: "loading",
  },
});

const getters = {
  recipient: (state) => state.recipient,
  status: (state) => state.recipient?.status,
  rfiSummary: (state) => state.rfiSummary,
  chartererName: (state) => state.rfiSummary?.Charterer,
  allQuestions: (state) => state.rfiSummary?.RfiQuestions,
  allAnswers: (state) => state.recipient?.answers,
  activeAnswer: (state) => state.activeAnswer,
  activeAnswerTextResponse: (state) => state.activeAnswer?.textResponse,
  activeAnswerMoreDetails: (state) => state.activeAnswer?.moreDetails,
  activeAnswerBooleanResponse: (state) => state.activeAnswer?.booleanResponse,
  activeAnswerDocuments: (state) => state.activeAnswer.documents.concat(state.activeAnswer.uploadFiles),
  isReview: (state) => state.isReview,
  currentQuestionIndex: (state) => state.currentQuestionIndex,
  isLoadingData: (state) => state.isLoadingData,
  recipientName: (state) => state.recipient?.ownerName,
  isSubmitting: (state) => state.isSubmitting,
  responseId: (state) => state.responseId,
  allRfiResponses: (state) => state.allRfiResponses,
  tableFilters: (state) => state.tableFilters,
  tableMetaData: (state) => state.tableMetaData,
  totalItemCount: (state) => state.tableMetaData.totalItemCount,
  tableStatus: (state) => state.tableMetaData.status,
  cachedRecipientAnswers: (state) => state.cachedRecipientAnswers,
  recipientAnswers: (state) => state.recipient?.answers,
  maxCustomPoints: (state) => state.recipient.maxCustomPoints,
  useCustomRatingScale: (state) => state.recipient.isCustomRatingScale,
};

const actions = {
  async getById({ commit, dispatch }, val) {
    commit("setIsLoadingData", true);
    var result = await getRequest("api/RfiResponse", `GetRecipientById/${val}`);
    if (!result) {
      return;
    }
    commit("setRecipient", mapRecipient(result.data));
    commit("setCachedRecipientAnswers", JSON.parse(JSON.stringify(mapRecipient(result.data).answers)));
    dispatch("getQuestionnaireSummaryById", result.data.RfiQuestionnaireId);
    commit("setIsLoadingData", false);
  },

  async getQuestionnaireSummaryById({ getters, commit }, val) {
    var result = await getRequest("api/RfiQuestionnaire", `GetSummaryById/${val}`);
    var isOwner = store.getters["authentication/isOwner"];
    commit("setRfiSummary", result.data);
    if (isOwner) {
      store.commit("layout/setBreadcrumbs", {
        items: ["rfis", "response"],
        overrideText: { response: `Response (${getters.recipientName})` },
        overrideLinks: { response: `/rfi-response/${getters.recipient.id}` },
      });
    } else {
      store.commit("layout/setBreadcrumbs", {
        items: ["rfis", "rfi", "response"],
        overrideText: { response: `Response (${getters.recipientName})` },
        overrideLinks: { rfi: `/rfi-response-summary/${val}`, response: `/rfi-response/${getters.recipient.id}` },
      });
    }
  },
  async saveAnswers({ getters, dispatch }) {
    //assign owner response id to all answers
    var answersToSubmit = JSON.parse(JSON.stringify(getters.recipient.answers));

    for (var i = 0; i < answersToSubmit.length; i++) {
      answersToSubmit[i].rfiRecipientId = getters.recipient.id;
      answersToSubmit[i].rfiQuestionId = getters.allQuestions[i].Id;
    }

    await postRequest("api/RfiResponse", `CreateOrUpdateAnswers`, answersToSubmit);
    dispatch("getById", getters.recipient.id);
  },
  async saveAnswer({ getters }) {
    //assign owner response id to all answers
    var answer = JSON.parse(JSON.stringify(getters.activeAnswer));
    answer.rfiRecipientId = getters.recipient.id;
    answer.rfiQuestionId = getters.allQuestions[state.currentQuestionIndex].Id;
    await postRequest("api/RfiResponse", `CreateOrUpdateAnswer`, answer);
  },
  async submit({ getters, commit }) {
    commit("setIsSubmitting", true);
    //assign owner response id to all answers
    var answersToSubmit = JSON.parse(JSON.stringify(getters.recipient.answers));

    for (var i = 0; i < answersToSubmit.length; i++) {
      answersToSubmit[i].rfiRecipientId = getters.recipient.id;
      answersToSubmit[i].rfiQuestionId = getters.allQuestions[i].Id;
    }

    await postRequest("api/RfiResponse", `SubmitAnswers`, answersToSubmit);
    commit("setIsSubmitting", false);
    commit("setRecipientStatus", "completed");
    commit("setIsReview", false);
    commit("setCurrentQuestionIndex", -1);
  },
  async getAllRfiResponses({ commit, getters }, id) {
    commit("setTableStatus", "loading");
    var result = await getRequest("api/RfiResponse", `GetAllByOwnerId/${id}`, getters.tableFilters);
    if (!result) {
      return;
    }
    commit("setAllRfiResponses", result.data.responses);
    commit("setTableTotalItemCount", result.data.total);
    commit("setTableStatus", "loaded");
  },
  async rateAnswer({ dispatch, getters, rootState }, data) {
    var method = `SetAnswerRating/${data.id}`;
    if (data.rating && data.rating != "null") {
      method += `?rating=${data.rating}`;
    }
    await postRequest("api/RfiReporting", method);
    if (data.parent == "recipient") {
      dispatch("getById", getters.recipient.id);
    } else {
      dispatch("rfiOverview/getById", rootState.rfiOverview.activeRfi.rfiQuestionnaire.id, { root: true });
    }
  },
};

const mutations = {
  setTableStatus(state, val) {
    state.tableMetaData.status = val;
  },
  setTableTotalItemCount(state, val) {
    state.tableMetaData.totalItemCount = val;
  },
  setRecipient(state, val) {
    state.recipient = val;
  },
  setCachedRecipientAnswers(state, val) {
    state.cachedRecipientAnswers = val;
  },
  setRfiSummary(state, val) {
    state.rfiSummary = val;
  },
  addAnswer(state) {
    var question = state.rfiSummary?.RfiQuestions[state.currentQuestionIndex + 1];
    state.recipient.answers.push(
      new Answer(undefined, undefined, state.recipient.id, undefined, undefined, undefined, question.Id, question.Type, [])
    );
  },
  updateAnswer(state) {
    Object.assign(state.recipient.answers[state.currentQuestionIndex], state.activeAnswer);
  },
  setActiveAnswer(state, val) {
    Object.assign(state.activeAnswer, val);
  },
  setActiveAnswerTextResponse(state, val) {
    state.activeAnswer.textResponse = val;
  },
  setActiveAnswerMoreDetails(state, val) {
    state.activeAnswer.moreDetails = val;
  },
  setActiveAnswerBooleanResponse(state, val) {
    state.activeAnswer.booleanResponse = val;
  },
  setIsReview(state, val) {
    state.isReview = val;
  },
  setCurrentQuestionIndex(state, val) {
    state.currentQuestionIndex = val;
  },
  setIsLoadingData(state, val) {
    state.isLoadingData = val;
  },
  setIsSubmitting(state, val) {
    state.isSubmitting = val;
  },
  setRecipientStatus(state, val) {
    state.recipient.status = val;
  },
  addDocument(state, val) {
    state.activeAnswer.documents.push({ FileDataBase64: val.base64, Filename: val.name });
  },
  removeDocument(state, val) {
    state.activeAnswer.documents.splice(val, 1);
  },
  setResponseId(state, val) {
    state.responseId = val;
  },
  setAllRfiResponses(state, val) {
    state.allRfiResponses = val;
  },
  setPage(state, val) {
    state.tableFilters.page = val;
  },
  setItemsPerPage(state, val) {
    state.tableFilters.itemsPerPage = val;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
