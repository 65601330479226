import { deleteRequest } from "@/helpers/requests.js";
const state = () => ({
  fileData: null,
  isUploading: false,
  activeRfiAnswerId: null,
});

const getters = {
  fileData: (state) => state.fileData,
  isUploading: (state) => state.isUploading,
  activeRfiAnswerId: (state) => state.activeRfiAnswerId,
};

const actions = {
  async deleteDocument(_, val) {
    await deleteRequest("api/RfiDocument", `Delete/${val}`);
  },
};

const mutations = {
  setIsUploading(state, val) {
    state.isUploading = val;
  },
  setFileData(state, val) {
    state.fileData = val;
  },
  setActiveRfiAnswerId(state, val) {
    state.activeRfiAnswerId = val;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
