import Vue from "vue";
import Vuex from "vuex";
import authentication from "./modules/authentication";
import questionnaire from "./modules/questionnaire";
import recipient from "./modules/recipient";
import template from "./modules/template";
import question from "./modules/question";
import lookup from "./modules/lookup";
import rfiOverview from "./modules/rfiOverview";
import recipientAnswer from "./modules/recipientAnswer";
import layout from "@/plugins/vuex/modules/layout";
import document from "@/plugins/vuex/modules/document";
import messages from "@/plugins/vuex/modules/messages";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    authentication,
    template,
    question,
    lookup,
    questionnaire,
    recipient,
    rfiOverview,
    recipientAnswer,
    layout,
    document,
    messages
  },
  strict: process.env.NODE_ENV !== "production",
});

export default store;
