<template>
  <v-dialog v-model="visible" max-width="500px" scrollable @click:outside="visible = false">
    <v-card align="center">
      <v-card-text class="subtitle-1 pt-3 text-center">
        <h3>{{ messageTitle }}</h3>
        <v-icon x-large v-if="messageType == 'information'" color="primary">mdi-information</v-icon>
        <v-icon x-large v-else-if="messageType == 'success'" color="success">mdi-check</v-icon>
        <v-icon x-large v-else-if="messageType == 'error'" color="error">mdi-alert-circle</v-icon>
        <p v-if="messageContent">{{ messageContent }}</p>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="visible = false" block>OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "message-window",
  methods: {},
  computed: {
    messageTitle() {
      return this.$store.getters["layout/messageTitle"];
    },
    messageType() {
      return this.$store.getters["layout/messageType"];
    },
    messageContent() {
      return this.$store.getters["layout/messageContent"];
    },
    visible: {
      get() {
        return this.$store.getters["layout/isMessageBoxVisible"];
      },
      set(val) {
        this.$store.commit("layout/setMessageBoxVisibility", val);
      },
    },
  },
};
</script>
