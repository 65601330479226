<template>
  <div id="app">
    <v-app>
      <navigation-menu v-if="showNavbar" :currentRoute="route.name"></navigation-menu>
      <v-main :class="showNavbar ? 'has-navigation' : ''">
        <v-container :class="shouldFillHeight ? 'fill-height' : ''">
          <view-breadcrumbs v-if="breadcrumbs.length > 1 && showNavbar"></view-breadcrumbs>
          <router-view :key="$route.fullPath" />
        </v-container>
      </v-main>
      <message-window></message-window>
    </v-app>
  </div>
</template>

<script>
import navigationMenu from "@/components/layout/navigationMenu";
import viewBreadcrumbs from "./components/layout/viewBreadcrumbs";
import messageWindow from "@/components/layout/messageWindow.vue";
export default {
  name: "App",
  components: { navigationMenu, viewBreadcrumbs, messageWindow },
  data() {
    return {
      route: "",
    };
  },
  computed: {
    breadcrumbs() {
      return this.$store.getters["layout/breadcrumbs"];
    },
    showNavbar() {
      return this.$route.meta.requiresAuthorization === true;
    },
    shouldFillHeight() {
      return this.$route.name === "Login" || this.$route.name === "Create Account";
    },
  },
  watch: {
    $route(to) {
      this.route = to;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.has-navigation {
  margin-top: 64px;
}
</style>
