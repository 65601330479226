export default {
  install(Vue, opts) {
    Vue.mixin({
      data() {
        return {};
      },
      methods: {
        downloadDocument(id) {
          if (!id) {
            window.alert("Document upload pending!");
            return;
          }
          var url = `${process.env.VUE_APP_API_URL}/api/RfiDocument/GetById/${id}`;
          window.open(url, "Download");
        },
      },
      computed: {
        isSeabrokersUser() {
          return opts.store.getters["authentication/isSeabrokers"];
        },
        isOwner() {
          return opts.store.getters["authentication/isOwner"];
        },
        isCharterer() {
          return opts.store.getters["authentication/isCharterer"];
        },
        userCompanyId() {
          return opts.store.getters["authentication/userCompanyId"];
        },
        userName() {
          return opts.store.getters["authentication/userName"];
        },
      },
    });
  },
};
