import axios from "axios";

export async function getRequest(controller, method, data) {
  const url = `${controller || ""}/${method}`;

  return axios
    .get(url, {
      params: data,
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function postRequest(controller, method, data, form = false) {
  if (form) return postForm(controller, method, data);

  const url = `${controller || ""}/${method}`;
  return axios.post(url, data).catch((error) => {
    alert(error.response.data);
    return null;
  });
}

export async function deleteRequest(controller, method, data) {
  const url = `${controller || ""}/${method}`;

  return axios
    .delete(url, {
      params: data,
    })
    .catch((error) => {
      console.log(error);
    });
}

async function postForm(controller, method, parameters) {
  var formData = new FormData();

  for (var key in parameters) {
    if (Object.hasOwn(parameters, key)) {
      if (parameters[key]) {
        formData.append(key, parameters[key]);
      }
    }
  }
  return axios.post(`/${controller}/${method}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
