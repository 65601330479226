import { getRequest, postRequest, deleteRequest } from "@/helpers/requests";
import { Template } from "@/helpers/objects/common";
import { mapTemplate } from "@/helpers/objects/mapper";
import store from "@/plugins/vuex/index";

const state = () => ({
  templates: [],
  editedTemplate: new Template(),
  tableFilters: {
    page: 1,
    itemsPerPage: 25,
    sortBy: null,
    sortDesc: false,
    chartererId: null,
  },
  tableMetaData: {
    totalItemCount: 0,
    status: "loading",
  },
  formStatus: null,
  templatesByCharterer: {},
});

const getters = {
  templates: (state) => state.templates,
  tableFilters: (state) => state.tableFilters,
  tableMetaData: (state) => state.tableMetaData,
  totalItemCount: (state) => state.tableMetaData.totalItemCount,
  tableStatus: (state) => state.tableMetaData.status,
  formStatus: (state) => state.formStatus,
  templatesByCharterer: (state) => state.templatesByCharterer,
  editedTemplate: (state) => state.editedTemplate,
  editedTemplateId: (state) => state.editedTemplate.id,
  editedTemplateTitle: (state) => state.editedTemplate.title,
  editedTemplateDescription: (state) => state.editedTemplate.description,
  editedTemplateQuestions: (state) => state.editedTemplate.questions,
  editedTemplateChartererId: (state) => state.editedTemplate.chartererId,
};

const actions = {
  async getData({ commit, getters }) {
    commit("setTableStatus", "loading");
    var result = await getRequest("api/RfiQuestionnaireTemplate", "Get", getters.tableFilters);
    if (!result) {
      return;
    }
    commit("setTemplates", result.data.templates);
    commit("setTableTotalItemCount", result.data.total);
    commit("setTableStatus", "loaded");
  },
  async createOrUpdate({ commit, getters, dispatch }) {
    commit("setFormStatus", "saving");
    var editedTemplateForSubmission = JSON.parse(JSON.stringify(getters.editedTemplate));
    editedTemplateForSubmission.rfiQuestions = JSON.parse(JSON.stringify(store.getters["question/activeQuestionList"]));

    var isSeabrokersUser = store.getters["authentication/isSeabrokers"];

    if (!editedTemplateForSubmission.id && !isSeabrokersUser) {
      var currentUserCompanyId = store.getters["authentication/userCompanyId"];
      editedTemplateForSubmission.chartererId = currentUserCompanyId;
    }

    await postRequest("api/RfiQuestionnaireTemplate", "CreateOrUpdate", editedTemplateForSubmission);
    commit("setFormStatus", null);
    dispatch("getData");
  },
  async getTemplateById({ commit }, id) {
    if (!id) {
      commit("setEditedTemplate", new Template());
    } else {
      var result = await getRequest(`api/RfiQuestionnaireTemplate`, `get/${id}`);
      commit("setEditedTemplate", result);
    }
  },
  async deleteTemplate({ getters, dispatch }) {
    await deleteRequest("api/RfiQuestionnaireTemplate", `Delete/${getters.editedTemplate.id}`);
    dispatch("getData");
  },
  async deleteTemplateById({ dispatch }, id) {
    await deleteRequest("api/RfiQuestionnaireTemplate", `Delete/${id}`);
    dispatch("getData");
  },
  async getTemplateList({ commit }) {
    var result = await getRequest("api/RfiQuestionnaireTemplate", "GetListByCharterer");
    if (!result) {
      return;
    }
    commit("setTempatesByCharterer", result.data);
  },
};

const mutations = {
  setTableStatus(state, val) {
    state.tableMetaData.status = val;
  },
  setTableTotalItemCount(state, val) {
    state.tableMetaData.totalItemCount = val;
  },
  setTemplates(state, val) {
    state.templates = val.map((x) => mapTemplate(x));
  },
  setEditedTemplate(state, val) {
    Object.assign(state.editedTemplate, val);
    store.commit("question/setActiveQuestionList", state.editedTemplate.rfiQuestions, { root: true });
  },
  setEditedTemplateTitle(state, val) {
    state.editedTemplate.title = val;
  },
  setEditedTemplateDescription(state, val) {
    state.editedTemplate.description = val;
  },
  setEditedTemplateChartererId(state, val) {
    state.editedTemplate.chartererId = val;
  },
  setFormStatus(state, val) {
    state.formStatus = val;
  },
  setPage(state, val) {
    state.tableFilters.page = val;
  },
  setItemsPerPage(state, val) {
    state.tableFilters.itemsPerPage = val;
  },
  clearEditedTemplate(state) {
    state.editedTemplate = new Template();
    store.commit("question/setActiveQuestionList", [], { root: true });
  },
  setTempatesByCharterer(state, val) {
    state.templatesByCharterer = val;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
