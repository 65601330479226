<template>
  <v-breadcrumbs :items="items" class="px-3">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item :to="{ path: `${item.href}` }" class="text-subtitle-2 crumb-item" :disabled="item.disabled" exact>
        {{ item.text }}
      </v-breadcrumbs-item>
    </template>
    <template v-slot:divider>
      <v-icon>mdi-chevron-right</v-icon>
    </template>
  </v-breadcrumbs>
</template>
<script>
export default {
  name: "view-breadcrumbs",
  props: {},
  data() {
    return {};
  },
  methods: {
    navigate(to) {
      this.$router.push({ path: to });
    },
  },
  computed: {
    items() {
      return this.$store.getters["layout/breadcrumbs"];
    },
  },
};
</script>
