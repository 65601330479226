import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";

const vuetifyOpts = {
  rtl: false,
  theme: {
    themes: {
      light: {
        primary: "#004996",
        accent: "#00B4E3",
        secondary: "#00B4E3",
        success: "#4CAF67",
        info: "#2196F3",
        warning: "#FF5B32",
        error: "#F43414",
      },
    },
  },
};

export default new Vuetify(vuetifyOpts);

Vue.use(Vuetify, {
  iconfont: "mdi",
});
