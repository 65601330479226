import { getRequest } from "@/helpers/requests";
import store from "@/plugins/vuex/index";

const state = () => ({
  charterers: [],
  owners: [],
  requirements: [],
  questionnaires: [],
  ownerResponseStatuses: [],
});

const getters = {
  charterers: (state) => state.charterers,
  owners: (state) => state.owners,
  requirements: (state) => state.requirements,
  questionnaires: (state) => state.questionnaires,
  ownerResponseStatuses: (state) => state.ownerResponseStatuses,
};

const actions = {
  async getCharterers({ commit }) {
    var result = await getRequest("lookup", "GetAllChartererCompaniesDropdown");
    if (!result) {
      return;
    }
    commit("setCharterers", result.data);
  },
  async getOwners({ commit }) {
    var result = await getRequest("publiclookup", "GetAllOwnerAndDesignerCompaniesDropdown");
    if (!result) {
      return;
    }
    commit("setOwners", result.data);
  },
  async getRequirements({ commit }) {
    var result = null;
    if (store.getters["authentication/isSeabrokers"]) {
      result = await getRequest("lookup", "GetAllOpenAndRfiRequirements");
    } else {
      result = await getRequest("publiclookup", "GetAllOpenAndRfiRequirementsByCharterer");
    }
    if (!result) {
      return;
    }
    commit("setRequirements", result.data);
  },
  async getQuestionnaires({ commit }) {
    var result = await getRequest("lookup", "GetAllQuestionnaires");
    if (!result) {
      return;
    }
    commit("setQuestionnaires", result.data);
  },
  async getOwnerResponseStatuses({ commit }) {
    var result = await getRequest("lookup", "GetRfiResponseStatuses");
    if (!result) {
      return;
    }
    commit("SetOwnerResponseStatuses", result.data);
  },
};

const mutations = {
  setCharterers(state, val) {
    state.charterers = val;
  },
  setOwners(state, val) {
    state.owners = val;
  },
  setRequirements(state, val) {
    state.requirements = val;
  },
  setQuestionnaires(state, val) {
    state.questionnaires = val;
  },
  SetOwnerResponseStatuses(state, val) {
    state.ownerResponseStatuses = val;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
