import { getRequest, postRequest, deleteRequest } from "@/helpers/requests";
import { mapQuestionnaireSummary, mapQuestionnaire } from "@/helpers/objects/mapper";
import { RfiQuestionnaire } from "@/helpers/objects/common";
import store from "@/plugins/vuex/index";
const state = () => ({
  questionnaires: [],
  editedQuestionnaire: new RfiQuestionnaire(),
  tableFilters: {
    page: 1,
    itemsPerPage: 10,
    sortBy: null,
    sortDesc: false,
    chartererId: null,
  },
  tableMetaData: {
    totalItemCount: 0,
    status: "loading",
  },
  formStatus: null,
});

const getters = {
  questionnaires: (state) => state.questionnaires,
  tableFilters: (state) => state.tableFilters,
  tableMetaData: (state) => state.tableMetaData,
  totalItemCount: (state) => state.tableMetaData.totalItemCount,
  tableStatus: (state) => state.tableMetaData.status,
  editedQuestionnaire: (state) => state.editedQuestionnaire,
  editedQuestionnaireRequirementId: (state) => state.editedQuestionnaire.requirementId,
  editedQuestionnaireId: (state) => state.editedQuestionnaire.id,
  editedQuestionnaireQuestionnaireId: (state) => state.editedQuestionnaire.rfiQuestionnaireId,
  editedQuestionnaireChartererId: (state) => state.editedQuestionnaire.chartererId,
  editedQuestionnaireProjectDescription: (state) => state.editedQuestionnaire.projectDescription,
  editedQuestionnaireSubmissionDeadline: (state) => state.editedQuestionnaire.submissionDeadline,
  editedQuestionnaireRecipients: (state) => state.editedQuestionnaire.rfiRecipients,
  editedQuestionnaireIsCustomRatingScale: (state) => state.editedQuestionnaire.isCustomRatingScale,
  editedQuestionnaireMaxCustomPoints: (state) => state.editedQuestionnaire.maxCustomPoints,
  formStatus: (state) => state.formStatus,
};

const actions = {
  async getData({ commit, getters }) {
    commit("setTableStatus", "loading");
    var result = await getRequest("api/RfiQuestionnaire", "GetSummaryItems", getters.tableFilters);
    if (!result) {
      return;
    }
    commit("setQuestionnaires", result.data.questionnaires);
    commit("setTableTotalItemCount", result.data.total);
    commit("setTableStatus", "loaded");
  },

  async createOrUpdate({ commit, getters, dispatch }) {
    commit("setFormStatus", "saving");

    var questionnaire = JSON.parse(JSON.stringify(getters.editedQuestionnaire));

    questionnaire.rfiQuestions = JSON.parse(JSON.stringify(store.getters["question/activeQuestionList"]));

    var isSeabrokersUser = store.getters["authentication/isSeabrokers"];

    if (!questionnaire.id && !isSeabrokersUser) {
      var currentUserCompanyId = store.getters["authentication/userCompanyId"];
      questionnaire.chartererId = currentUserCompanyId;
    }

    for (var i = 0; i < questionnaire.rfiQuestions.length; i++) {
      questionnaire.rfiQuestions[i].chartererId = questionnaire.chartererId;
    }
    await postRequest("api/RfiQuestionnaire", "CreateOrUpdate", questionnaire);
    commit("setFormStatus", null);
    dispatch("getData");
  },
  async getById({ commit }, id) {
    var result = await getRequest("api/RfiQuestionnaire", `GetById/${id}`);
    commit("setEditedQuestionnaire", mapQuestionnaire(result.data));
  },
  async deleteQuestionnaire({ getters }) {
    await deleteRequest("api/RfiQuestionnaire", `Delete/${getters.editedQuestionnaireId}`);
  },
  async deleteQuestionnaireById(_, id) {
    await deleteRequest("api/RfiQuestionnaire", `Delete/${id}`);
  },
};

const mutations = {
  setTableStatus(state, val) {
    state.tableMetaData.status = val;
  },
  setTableTotalItemCount(state, val) {
    state.tableMetaData.totalItemCount = val;
  },
  setQuestionnaires(state, val) {
    state.questionnaires = val.map((x) => mapQuestionnaireSummary(x));
  },
  setEditedQuestionnaireById(state, val) {
    var questionnaire = state.questionnaires.filter((x) => x.id == val)[0];
    Object.assign(state.editedQuestionnaire, questionnaire);
    store.commit("question/setActiveQuestionList", state.editedQuestionnaire.rfiQuestions, { root: true });
  },
  setEditedQuestionnaire(state, val) {
    Object.assign(state.editedQuestionnaire, val);
    store.commit("question/setActiveQuestionList", state.editedQuestionnaire.rfiQuestions, { root: true });
  },
  setFormStatus(state, val) {
    state.formStatus = val;
  },
  setPage(state, val) {
    state.tableFilters.page = val;
  },
  setItemsPerPage(state, val) {
    state.tableFilters.itemsPerPage = val;
  },
  setEditedQuestionnaireRequirementId(state, val) {
    state.editedQuestionnaire.requirementId = val;
  },
  setEditedQuestionnaireQuestionnaireId(state, val) {
    state.editedQuestionnaire.rfiQuestionnaireId = val;
  },
  setEditedQuestionnaireProjectDescription(state, val) {
    state.editedQuestionnaire.projectDescription = val;
  },
  setEditedQuestionnaireChartererId(state, val) {
    state.editedQuestionnaire.chartererId = val;
  },
  setEditedQuestionnaireSubmissionDeadline(state, val) {
    state.editedQuestionnaire.submissionDeadline = val;
  },
  setEditedQuestionnaireIsCustomRatingScale(state, val) {
    state.editedQuestionnaire.isCustomRatingScale = val;
  },
  setEditedQuestionnaireMaxCustomPoints(state, val) {
    state.editedQuestionnaire.maxCustomPoints = val;
  },
  addRecipient(state, val) {
    val.status = "unsent";
    state.editedQuestionnaire.rfiRecipients.push(val);
  },
  editRecipient(state, val) {
    Object.assign(state.editedQuestionnaire.rfiRecipients[val.index], val.owner);
  },
  removeRecipient(state, index) {
    store.dispatch("recipient/delete");
    state.editedQuestionnaire.rfiRecipients.splice(index, 1);
  },
  clearEditedRequirementQuestionnaire(state) {
    state.editedQuestionnaire = new RfiQuestionnaire();
    store.commit("question/setActiveQuestionList", [], { root: true });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
