import { postRequest, deleteRequest, getRequest } from "@/helpers/requests";
import { Question } from "@/helpers/objects/common";
import { mapOrphanQuestion } from "@/helpers/objects/mapper";
import { moveArrayItem } from "@/helpers/arrayHelpers";
import store from "@/plugins/vuex/index";
const state = () => ({
  editedQuestion: new Question(),
  editedQuestionIndex: null,
  questionTypes: [
    { value: "yes-or-no", display: "Yes / No" },
    { value: "text", display: "Free text response" },
    { value: "multiple", display: "Multiple choice" },
    { value: "document", display: "Document only" },
  ],
  additionalTextBoxOptions: [
    { value: "yes", display: "Yes" },
    { value: "no", display: "No" },
    { value: "on-select-yes", display: "Only on selecting yes", includeInBooleanOnly: true },
    { value: "on-select-no", display: "Only on selecting no", includeInBooleanOnly: true },
  ],
  formStatus: null,
  tableFilters: {
    page: 1,
    itemsPerPage: 25,
    sortBy: null,
    sortDesc: false,
    chartererId: null,
  },
  tableMetaData: {
    totalItemCount: 0,
    status: "loading",
  },
  activeQuestionList: [],
  editedQuestionMultipleChoiceNewOption: "",
});

const getters = {
  editedQuestion: (state) => state.editedQuestion,
  editedQuestionText: (state) => state.editedQuestion.text,
  editedQuestionType: (state) => state.editedQuestion.type,
  editedQuestionImportance: (state) => state.editedQuestion.importance,
  editedQuestionMultipleChoiceAnswers: (state) => state.editedQuestion.multipleChoiceAnswers,
  editedQuestionShowTextBox: (state) => state.editedQuestion.showTextBox,
  editedQuestionIsDocumentUpload: (state) => state.editedQuestion.isDocumentUpload,
  editedQuestionId: (state) => state.editedQuestion.id,
  questionTypes: (state) => state.questionTypes,
  additionalTextBoxOptions: (state) => state.additionalTextBoxOptions,
  editedQuestionIndex: (state) => state.editedQuestionIndex,
  formStatus: (state) => state.formStatus,
  activeQuestionList: (state) => state.activeQuestionList,
  editedQuestionMultipleChoiceNewOption: (state) => state.editedQuestionMultipleChoiceNewOption
};

const actions = {
  async createOrUpdate({ commit, getters }) {
    commit("setFormStatus", "saving");
    await postRequest("api/RfiQuestion", "CreateOrUpdateQuestion", getters.editedQuestion);
    commit("updateQuestion");
    commit("setFormStatus", null);
  },
  async delete({ getters }) {
    await deleteRequest("api/RfiQuestion", `Delete/${getters.editedQuestionId}`);
  },
  async setQuestionsByTemplate({ commit }, val) {
    var result = await getRequest("api/RfiQuestion", `GetByTemplateId/${val}`);
    commit(
      "setActiveQuestionList",
      result.data.map((x) => mapOrphanQuestion(x))
    );
  },
};

const mutations = {
  setEditedQuestion(state, val) {
    if (!val) {
      val = {};
      val.question = new Question();
      state.editedQuestionIndex = -1;
    } else {
      state.editedQuestionIndex = val.index;
    }
    Object.assign(state.editedQuestion, val.question);
  },
  setEditedQuestionText(state, val) {
    state.editedQuestion.text = val;
  },
  setEditedQuestionType(state, val) {
    if (val == "document") state.editedQuestion.isDocumentUpload = true;
    state.editedQuestion.type = val;
  },
  setEditedQuestionImportance(state, val) {
    state.editedQuestion.importance = val;
  },
  setEditedQuestionMultipleChoiceAnswers(state, val) {
    state.editedQuestion.multipleChoiceAnswers = val;
  },
  setEditedQuestionShowTextBox(state, val) {
    state.editedQuestion.showTextBox = val;
  },
  setEditedQuestionIsDocumentUpload(state, val) {
    state.editedQuestion.isDocumentUpload = val;
  },
  setFormStatus(state, val) {
    state.formStatus = val;
  },
  setPage(state, val) {
    state.tableFilters.page = val;
  },
  setItemsPerPage(state, val) {
    state.tableFilters.itemsPerPage = val;
  },
  setEditedQuestionChartererId(state, val) {
    state.editedQuestion.chartererId = val;
  },
  setEditedQuestionMultipleChoiceNewOption(state, val) {
    state.editedQuestionMultipleChoiceNewOption = val;
  },
  addQuestionMultiChoiceAnswer(state, val) {
    var asArray = state.editedQuestion.multipleChoiceAnswers ? state.editedQuestion.multipleChoiceAnswers.split(", ") : [];
    asArray.push(val);
    state.editedQuestion.multipleChoiceAnswers = asArray.join(", ");
  },
  removeQuestionMultiChoiceAnswer(state, index) {
    var asArray = state.editedQuestion.multipleChoiceAnswers ? state.editedQuestion.multipleChoiceAnswers.split(", ") : [];
    asArray.splice(index, 1);
    state.editedQuestion.multipleChoiceAnswers = asArray.join(", ");
  },
  shiftQuestionMultiChoiceAnswer(state, val) {
    var asArray = state.editedQuestion.multipleChoiceAnswers ? state.editedQuestion.multipleChoiceAnswers.split(", ") : [];
    moveArrayItem(asArray, val.oldIndex, val.newIndex);
    state.editedQuestion.multipleChoiceAnswers = asArray.join(", ");
  },
  clearEditedQuestion(state) {
    state.editedQuestion = new Question();
  },
  setActiveQuestionList(state, val) {
    state.activeQuestionList = val;
  },
  addQuestion(state) {
    state.activeQuestionList.push(JSON.parse(JSON.stringify(state.editedQuestion)));
  },
  updateQuestion(state) {
    Object.assign(state.activeQuestionList[state.editedQuestionIndex], JSON.parse(JSON.stringify(state.editedQuestion)));
  },
  removeQuestion(state) {
    state.activeQuestionList.splice(state.editedQuestionIndex, 1);
    if (state.editedQuestion.id) {
      store.dispatch("question/delete");
    }
  },

  updateActiveQuestionMultiselectOption(state, val) {
    var asArray = state.editedQuestion.multipleChoiceAnswers ? state.editedQuestion.multipleChoiceAnswers.split(", ") : [];
    asArray[val.index] = val.text;
    state.editedQuestion.multipleChoiceAnswers = asArray.join(", ");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
