import { Recipient } from "@/helpers/objects/common";
import { deleteRequest } from "@/helpers/requests";

const state = () => ({
  activeRecipient: new Recipient(),
  activeRecipientIndex: -1,
});

const getters = {
  activeRecipient: (state) => state.activeRecipient,
  activeRecipientCompanyId: (state) => state.activeRecipient.ownerId,
  activeRecipientContactEmailAddress: (state) => state.activeRecipient.contactEmailAddress,
  activeRecipientContactName: (state) => state.activeRecipient.contactName,
  activeRecipientStatus: (state) => state.activeRecipient.status,
  activeRecipientId: (state) => state.activeRecipient.id,
  activeRecipientIndex: (state) => state.activeRecipientIndex,
  activeRecipientCompanyName: (state) => state.activeRecipient.ownerName,
};

const actions = {
  async delete({ getters }) {
    if (!getters.activeRecipientId) return;
    await deleteRequest("api/RfiResponse", `DeleteRecipient/${getters.activeRecipientId}`);
  },
};

const mutations = {
  setActiveRecipient(state, val) {
    if (!val) {
      val = {};
      val.owner = new Recipient();
      state.activeRecipientIndex = -1;
    } else {
      state.activeRecipientIndex = val.index;
    }
    Object.assign(state.activeRecipient, val.owner);
  },
  setActiveRecipientContactEmailAddress(state, val) {
    state.activeRecipient.contactEmailAddress = val;
  },
  setActiveRecipientContactName(state, val) {
    state.activeRecipient.contactName = val;
  },
  setActiveRecipientCompanyId(state, val) {
    state.activeRecipient.ownerId = val;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
