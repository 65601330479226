import { getRequest, postRequest } from "@/helpers/requests";
import store from "@/plugins/vuex/index";

const state = () => ({
  userName: null,
  userEmailAddress: null,
  userStatus: null,
  userRole: null,
  userCompanyId: null,
  signinEmail: null,
  signinPassword: null,
  isLoggingIn: false,
  loginError: false,
});

const getters = {
  signinPassword: (state) => state.signinPassword,
  signinEmail: (state) => state.signinEmail,
  userStatus: (state) => state.userStatus,
  isAuthenticated: (state) => state.userStatus === "logged-in",
  userRole: (state) => state.userRole,
  userEmail: (state) => state.userEmailAddress,
  userName: (state) => state.userName,
  isSeabrokers: (state) =>
    state.userRole == "ADM" || state.userRole == "SHP" || state.userRole == "SUP" || state.userRole == "ALY" || state.userRole == "ACC",
  isOwner: (state) => state.userRole == "OWN",
  isCharterer: (state) => state.userRole == "CHA",
  isLoggingIn: (state) => state.isLoggingIn,
  loginError: (state) => state.loginError,
  userCompanyId: (state) => state.userCompanyId,
};

const actions = {
  async authenticate({ commit }) {
    var result = await getRequest("portal", "GetUserData");
    if (!result) {
      return;
    }
    commit("setUserStatus", result.data ? "logged-in" : null);
    if (result.data) {
      commit("setUserStatus", "logged-in");
      commit("setUserData", result.data.userData);
    }
    commit("setIsLoggingIn", false);
    return result.data;
  },
  async login({ commit, getters, dispatch }) {
    commit("setLoginError", false);
    commit("setIsLoggingIn", true);
    var result = await postRequest("portal", "login", { email: getters.signinEmail, password: getters.signinPassword }, true);
    if (!result || !result.data.success) {
      commit("setIsLoggingIn", false);
      commit("setLoginError", true);
      return;
    }
    await dispatch("authenticate");
  },
  async logout({ commit }) {
    await getRequest("portal", "logout");
    commit("clearUserData");
  },
  async createAccount({ commit, getters, dispatch }) {
    commit("setLoginError", false);
    commit("setIsLoggingIn", true);
    var recipientId = store.getters["recipientAnswer/responseId"];
    var result = await postRequest("api/RfiUser", `Create/${recipientId}?password=${getters.signinPassword}`, {}, true);
    if (!result || !result.data.success) {
      commit("setIsLoggingIn", false);
      commit("setLoginError", true);
      return;
    }
    await dispatch("login");
  },
};

const mutations = {
  setUserStatus(state, val) {
    state.userStatus = val;
  },
  setUserData(state, val) {
    state.userRole = val.role;
    state.userName = val.name;
    state.userEmailAddress = val.emailAddress;
    state.userCompanyId = val.companyId;
  },
  clearUserData(state) {
    state.userStatus = null;
    state.userRole = null;
    state.userName = null;
    state.userEmailAddress = null;
  },
  setSigninEmail(state, val) {
    state.signinEmail = val;
  },
  setSigninPassword(state, val) {
    state.signinPassword = val;
  },
  setIsLoggingIn(state, val) {
    state.isLoggingIn = val;
  },
  setLoginError(state, val) {
    state.loginError = val;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
