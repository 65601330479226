import {
  Template,
  Question,
  RfiQuestionnaire,
  Recipient,
  Answer,
  Owner,
  Requirement,
  RfiQuestionnaireReport,
  RfiQuestionnaireSummary,
  RfiMessage,
} from "@/helpers/objects/common";

function mapTemplate(x) {
  return new Template(
    x.ChartererId,
    x.ChartererName,
    x.Id,
    x.ModifiedDateDisplay,
    x.Title,
    x.Description,
    x.RfiQuestions ? x.RfiQuestions.map((y) => mapQuestion(y)) : []
  );
}

function mapQuestion(x) {
  return new Question(
    x.Id,
    x.ModifiedDateDisplay,
    x.Text,
    x.Type,
    x.Importance,
    x.IsDocumentUpload,
    x.ChartererId,
    x.ShowTextBox,
    x.MultipleChoiceAnswers,
    x.RfiQuestionnaireId,
    x.RfiQuestionnaireTemplateId
  );
}
function mapOrphanQuestion(x) {
  return new Question(
    null,
    x.ModifiedDateDisplay,
    x.Text,
    x.Type,
    x.Importance,
    x.IsDocumentUpload,
    x.ChartererId,
    x.ShowTextBox,
    x.MultipleChoiceAnswers,
    null,
    null
  );
}
function mapOwner(x) {
  return new Owner(x.Name);
}

function mapAnswer(x) {
  return new Answer(
    x.Id,
    x.ModifiedDateDisplay,
    x.RfiRecipientId,
    x.TextResponse,
    x.MoreDetails,
    x.BooleanResponse,
    x.RfiQuestionId,
    x.RfiQuestionType,
    x.Documents,
    x.ChartererRating
  );
}

function mapRecipient(x) {
  return new Recipient(
    x.Id,
    x.ModifiedDateDisplay,
    x.RfiQuestionnaireId,
    x.OwnerId,
    x.RfiAnswers.map((y) => mapAnswer(y)),
    x.Status,
    x.ContactEmailAddress,
    x.ContactName,
    x.OwnerName,
    x.NotificationEmailLastSent,
    x.RfiQuestionnaireMaxCustomPoints,
    x.RfiQuestionnaireIsCustomRatingScale
  );
}

function mapQuestionnaire(x) {
  return new RfiQuestionnaire(
    x.Id,
    x.ModifiedDateDisplay,
    x.RfiQuestions.map((z) => mapQuestion(z)),
    x.RfiRecipients.map((y) => mapRecipient(y)),
    x.Requirement ? mapRequirement(x.Requirement) : null,
    x.Charterer,
    x.ChartererId,
    x.QuestionnaireTitle,
    x.ProjectDescription,
    x.SubmissionDeadline,
    x.IsCustomRatingScale,
    x.MaxCustomPoints
  );
}
function mapQuestionnaireSummary(x) {
  return new RfiQuestionnaireSummary(
    x.Id,
    x.ModifiedDateDisplay,
    x.Requirement ? mapRequirement(x.Requirement) : null,
    x.Charterer,
    x.ChartererId,
    x.QuestionnaireTitle,
    x.ProjectDescription,
    x.SubmissionDeadline
  );
}
function mapRfiReport(x) {
  return new RfiQuestionnaireReport(x);
}

function mapRequirement(x) {
  return new Requirement(x.RequirementId, x.Charterer, x.RequirementSummary);
}

function mapMessage(x) {
  return new RfiMessage(x.Content, x.Type, x.Created, x.CreatedBy);
}
export {
  mapQuestionnaire,
  mapQuestionnaireSummary,
  mapTemplate,
  mapOwner,
  mapAnswer,
  mapRecipient,
  mapRequirement,
  mapOrphanQuestion,
  mapRfiReport,
  mapMessage,
};
