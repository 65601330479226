const state = () => ({
  breadcrumbs: [],
  allBreadcrumbs: {
    rfi: {
      text: "RFI Response Summary",
      disabled: false,
      href: "/rfi-response-summary",
    },
    response: {
      text: "RFI Response",
      disabled: false,
      href: "/rfiResponse",
    },
    templates: {
      text: "RFI Templates",
      disabled: false,
      href: "/templates",
    },
    rfis: {
      text: "RFI's",
      disabled: false,
      href: "/rfi-list",
    },
  },
  navItems: [
    { to: "/rfi-list", text: "Rfi's", name: "questionnaires" },
    { to: "/templates", text: "Templates", name: "templates" },
  ],
  message: {
    visible: false,
    title: "",
    content: "",
    type: "",
  },
});

const getters = {
  breadcrumbs: (state) => state.breadcrumbs,
  navItems: (state) => state.navItems,
  allBreadcrumbs: (state) => state.allBreadcrumbs,
  isMessageBoxVisible: (state) => state.message.visible,
  messageTitle: (state) => state.message.title,
  messageContent: (state) => state.message.content,
  messageType: (state) => state.message.type,
};

const actions = {};

const mutations = {
  setBreadcrumbs(state, val) {
    var allBreadCrumbs = JSON.parse(JSON.stringify(state.allBreadcrumbs));
    if (val.overrideText) {
      var keys = Object.keys(val.overrideText);
      for (var i = 0; i < keys.length; i++) {
        var overrideValue = val.overrideText[keys[i]];
        if (overrideValue.length > 50) overrideValue = `${overrideValue.substring(0, 50)}...`;
        allBreadCrumbs[keys[i]].text = overrideValue;
      }
    }
    if (val.overrideLinks) {
      var linkKeys = Object.keys(val.overrideLinks);
      for (var x = 0; x < linkKeys.length; x++) {
        var linkOverrideValue = val.overrideLinks[linkKeys[x]];
        allBreadCrumbs[linkKeys[x]].href = linkOverrideValue;
      }
    }

    var result = val.items.map((x) => allBreadCrumbs[x]);

    state.breadcrumbs = result;
  },
  setMessageBoxVisibility(state, val) {
    state.message.visible = val;
  },
  setMessageBoxDetails(state, val) {
    state.message.title = val.title;
    state.message.type = val.type;
    state.message.content = val.content;
    state.message.visible = true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
