import { getRequest, postRequest } from "@/helpers/requests";
import { mapMessage } from "@/helpers/objects/mapper";
import { RfiMessage } from "@/helpers/objects/common.js";

const state = () => ({
  messages: [],
  activeMessage: new RfiMessage(),
  parentId: null,
  type: null,
  inlineMessageRecipientId: null,
  inlineMessage: new RfiMessage(),
  inlineMessageDialogVisible: false,
});

const getters = {
  messages: (state) => state.messages,
  activeMessageContent: (state) => state.activeMessage.content,
  activeMessageType: (state) => state.activeMessage.type,
  parentId: (state) => state.parentId,
  type: (state) => state.type,
  activeMessage: (state) => state.activeMessage,
  inlineMessage: (state) => state.inlineMessage,
  inlineMessageRecipientId: (state) => state.inlineMessageRecipientId,
  inlineMessageContent: (state) => state.inlineMessage.content,
  inlineMessageDialogVisible: (state) => state.inlineMessageDialogVisible,
};

const actions = {
  async get({ commit, getters }) {
    var result = await getRequest("api/RfiMessage", `Get${getters.type == "rfi" ? "" : "ByRecipient"}/${getters.parentId}`);
    commit(
      "setMessages",
      result.data.map((x) => mapMessage(x))
    );
  },

  async sendInlineMessage({ getters, rootState, commit }) {
    var model = JSON.parse(JSON.stringify(getters.inlineMessage));
    model.parentId = getters.inlineMessageRecipientId;
    model.createdBy = rootState.authentication.userName;
    await postRequest("api/RfiMessage", `Create`, model);
    commit("setInlineMessageContent", null);
    commit("setInlineMessageRecipientId", null);
    commit("setInlineMessageDialogVisible", false);
  },

  async addMessage({ getters, rootState, dispatch, commit }) {
    var model = JSON.parse(JSON.stringify(getters.activeMessage));
    model.parentId = getters.parentId;
    model.createdBy = rootState.authentication.userName;
    await postRequest("api/RfiMessage", `Create`, model);
    commit("setActiveMessageContent", null);

    dispatch("get");
  },
};

const mutations = {
  setMessages(state, val) {
    state.messages = val;
  },
  setParentId(state, val) {
    state.parentId = val;
  },
  setType(state, val) {
    state.type = val;
  },
  setActiveMessageContent(state, val) {
    state.activeMessage.content = val;
  },
  setInlineMessageContent(state, val) {
    state.inlineMessage.content = val;
  },
  setInlineMessageRecipientId(state, val) {
    state.inlineMessageRecipientId = val;
  },
  setInlineMessageDialogVisible(state, val) {
    state.inlineMessageDialogVisible = val;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
