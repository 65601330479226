import { getRequest, postRequest } from "@/helpers/requests";
import { mapRfiReport } from "@/helpers/objects/mapper";
import { RfiQuestionnaireReport } from "@/helpers/objects/common.js";

import store from "@/plugins/vuex/index";

const state = () => ({
  activeRfi: new RfiQuestionnaireReport(),
  isRecipientTableLoading: false,
  rfiResponseFilters: {
    pointsRange: [],
  },
});

const getters = {
  activeRfi: (state) => state.activeRfi,
  activeRfiResponsesSummary: (state) => state.activeRfi?.rfiRecipientSummary,
  activeRfiTotalCount: (state) => state.activeRfi?.stats.totalCount,
  activeRfiUnsentCount: (state) => state.activeRfi?.stats.unsentCount,
  activeRfiNoResponseCount: (state) => state.activeRfi?.stats.noResponseCount,
  activeRfiInProgressCount: (state) => state.activeRfi?.stats.inProgressCount,
  activeRfiCompletedCount: (state) => state.activeRfi?.stats.completedCount,
  activeRfiAllResponsesByQuestion: (state) => state.activeRfi?.allResponsesGroupedByQuestion,
  activeRfiAllResponsesByRecipient: (state) => state.activeRfi?.rfiQuestionnaire.rfiRecipients,
  activeRfiPolarChartData: (state) => state.activeRfi?.polarChartData,
  activeRfiAllQuestions: (state) => state.activeRfi?.allQuestions,
  activeRfiUseCustomRating: (state) => state.activeRfi?.rfiQuestionnaire.isCustomRatingScale,
  activeRfiMaxCustomPoints: (state) => state.activeRfi?.rfiQuestionnaire.maxCustomPoints,
  activeRfiSubmissionDeadline: (state) => state.activeRfi?.rfiQuestionnaire.submissionDeadline,
  summary: (state) => state.activeRfi?.rfiQuestionnaire?.summary,
  isRecipientTableLoading: (state) => state.isRecipientTableLoading,
  rfiResponseFilters: (state) => state.rfiResponseFilters,
  pointsRangeFilter: (state) => state.rfiResponseFilters.pointsRange,
  activeRfiMaximumAllowablePoints: (state) =>
    state.activeRfi ? state.activeRfi.allQuestions.length * state.activeRfi.polarChartData.MaximumPoints : -1,
  allRecipients: (state) => state.activeRfi?.rfiQuestionnaire.rfiRecipients,
};

const actions = {
  async getById({ commit, getters }, val) {
    commit("setIsRecipientTableLoading", true);
    var result = await getRequest("api/RfiReporting", `GetById/${val}`);
    if (!result) {
      return;
    }
    commit("setActiveRfi", mapRfiReport(result.data));
    store.commit("layout/setBreadcrumbs", {
      items: ["rfis", "rfi"],
      overrideLinks: { rfi: `/rfi-response-summary/${getters.activeRfi.rfiQuestionnaire.id}` },
    });
    commit("setIsRecipientTableLoading", false);
  },
  async sendNotificationToAllRecipients({ getters, dispatch }) {
    if (getters.activeRfi.rfiQuestionnaire.deadlineHasPassed()) {
      alert("The deadline for submitting this RFI has passed!");
      return;
    }
    var id = getters.activeRfi.rfiQuestionnaire.id;
    if (!id) return;
    await getRequest("api/RfiResponse", `SendNotificationToAllRecipients/${id}`);
    dispatch("getById", id);
    store.commit("layout/setMessageBoxDetails", { title: "Notification Emails Sent", type: "success", content: "" });
  },
  async sendNotificationToRecipient({ getters, dispatch }, id) {
    if (!getters.activeRfi.rfiQuestionnaire.id) return;
    await getRequest("api/RfiResponse", `SendNotificationToRecipient/${getters.activeRfi.rfiQuestionnaire.id}`, { recipientId: id });
    store.commit("layout/setMessageBoxDetails", { title: "Notification Email Sent", type: "success", content: "" });
    dispatch("getById", getters.activeRfi.rfiQuestionnaire.id);
  },
  async updateAnswerRating({ dispatch, getters }, data) {
    var method = `SetAnswerRating/${data.id}`;
    if (data.rating && data.rating != "null") {
      method += `?rating=${data.rating}`;
    }
    await postRequest("api/RfiReporting", method);
    dispatch("getById", getters.activeRfi.rfiQuestionnaire.id);
  },
};

const mutations = {
  setActiveRfi(state, val) {
    state.activeRfi = val;
  },
  setIsRecipientTableLoading(state, val) {
    state.isRecipientTableLoading = val;
  },
  setRfiResponseFilters(state, val) {
    state.rfiResponseFilters = val;
  },
  setPointsRange(state, val) {
    state.rfiResponseFilters.pointsRange = val;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
