import Vue from "vue";
import Router from "vue-router";
import store from "@/plugins/vuex/index";
Vue.use(Router);

const routes = [
  { path: "/login", component: () => import("@/views/Login.vue"), name: "Login", meta: { requiresAuthorization: false } },
  {
    path: "/templates",
    component: () => import("@/views/QuestionnaireTemplates.vue"),
    name: "templates",
    meta: { requiresAuthorization: true },
  },
  {
    path: "/rfi-list",
    component: () => import("@/views/Questionnaires.vue"),
    name: "questionnaires",
    meta: {
      requiresAuthorization: true,
    },
  },
  {
    path: "/rfi-response-summary/:id",
    component: () => import("@/views/RfiResponseSummary.vue"),
    name: "RFI Details",
    meta: {
      requiresAuthorization: true,
    },
  },
  {
    path: "/rfi-response/:id",
    component: () => import("@/views/RfiResponseView.vue"),
    name: "Request for Information",
    meta: {
      requiresAuthorization: true,
    },
  },
  {
    path: "/create-account",
    component: () => import("@/views/CreateAccount.vue"),
    name: "Create Account",
    meta: {
      requiresAuthorization: false,
    },
  },
  {
    path: "*",
    redirect: "/login",
  },
  { path: "/", redirect: "/rfi-list" },
];

const router = new Router({
  mode: "history",
  routes: routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.name == "unauthorized" || to.params.isUnauthorizedRedirect) {
    next();
  } else {
    var authenticated = store.getters["authentication/isAuthenticated"] == true ? true : await store.dispatch("authentication/authenticate");

    if (authenticated || to.meta.requiresAuthorization === false) {
      if (to.name == "Login" && authenticated) {
        next({ name: "questionnaires" });
      } else {
        next();
      }
    } else {
      if (to.name == "Request for Information") {
        next({ name: "Create Account", query: { email: to.query.email, id: to.params.id } });
      } else {
        next({ name: "Login", params: { isUnauthorizedRedirect: true } });
      }
    }
  }
});

export default router;
