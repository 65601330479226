import { mapAnswer, mapQuestionnaire } from "./mapper.js";
import { toDateTimeString } from "@/helpers/commonFunctions.js";
import { DateTime } from "luxon";
class BaseObject {
  constructor(id, modified) {
    this.id = id;
    this.modifiedDateDisplay = modified;
  }
}

class Template extends BaseObject {
  constructor(chartererId = null, chartererName = "", id = null, modified = null, title = "", description = "", rfiQuestions = []) {
    super(id, modified);
    this.title = title;
    this.description = description;
    this.rfiQuestions = rfiQuestions;
    this.chartererId = chartererId;
    this.chartererName = chartererName;
  }
}

class Question extends BaseObject {
  constructor(
    id = null,
    modified = null,
    text = "",
    type = "yes-or-no",
    importance = "medium",
    isDocumentUpload = false,
    chartererId,
    showTextBox = false,
    multipleChoiceAnswers = "",
    rfiQuestionnaireId = null,
    rfiQuestionnaireTemplateId = null
  ) {
    super(id, modified);
    this.text = text;
    this.type = type;
    this.importance = importance;
    this.chartererId = chartererId;
    this.showTextBox = showTextBox;
    this.multipleChoiceAnswers = multipleChoiceAnswers;
    this.rfiQuestionnaireId = rfiQuestionnaireId;
    this.rfiQuestionnaireTemplateId = rfiQuestionnaireTemplateId;
    this.isDocumentUpload = isDocumentUpload;
  }
}

class RfiQuestionnaire extends BaseObject {
  constructor(
    id = null,
    modified = null,
    rfiQuestions = [],
    recipients = [],
    requirement = new Requirement(),
    charterer = "",
    chartererId = null,
    title = "",
    projectDescription = "",
    submissionDeadline = null,
    isCustomRatingScale = false,
    maxCustomPoints = 0
  ) {
    super(id, modified);
    this.rfiRecipients = recipients;
    (this.rfiQuestions = rfiQuestions), (this.requirement = requirement);
    this.charterer = charterer;
    this.title = title;
    this.requirementId = requirement ? requirement.id : null;
    this.summary = requirement ? requirement.summary : projectDescription;
    this.projectDescription = projectDescription;
    this.chartererId = chartererId;
    this.submissionDeadline = submissionDeadline;
    (this.isCustomRatingScale = isCustomRatingScale), (this.maxCustomPoints = maxCustomPoints);
  }

  questionHasResponse(questionId) {
    var responses = this.rfiRecipients.filter((x) => x.answers.filter((y) => y.questionId == questionId).length > 0);
    return responses.length > 0;
  }
  deadlineHasPassed() {
    if (!this.submissionDeadline) return false;

    var now = new Date();
    return DateTime.fromISO(this.submissionDeadline) - now < 0;
  }
}
class RfiQuestionnaireSummary extends BaseObject {
  constructor(
    id = null,
    modified = null,
    requirement = new Requirement(),
    charterer = "",
    chartererId = null,
    title = "",
    projectDescription = "",
    submissionDeadline = null
  ) {
    super(id, modified);
    this.charterer = charterer;
    this.title = title;
    this.requirementId = requirement ? requirement.id : null;
    this.summary = requirement ? requirement.summary : projectDescription;
    this.projectDescription = projectDescription;
    this.chartererId = chartererId;
    this.submissionDeadline = submissionDeadline;
  }
}

class Recipient extends BaseObject {
  constructor(
    id = null,
    modified = null,
    rfiQuestionnaireId = null,
    ownerId = null,
    answers = [],
    status = "",
    contactEmailAddress = "",
    contactName = "",
    ownerName = "",
    emailSent = null,
    maxCustomPoints = null,
    isCustomRatingScale = false
  ) {
    super(id, modified);
    this.rfiQuestionnaireId = rfiQuestionnaireId;
    this.ownerId = ownerId;
    this.answers = answers;
    this.status = status;
    this.contactEmailAddress = contactEmailAddress;
    this.contactName = contactName;
    this.ownerName = ownerName;
    this.emailSent = emailSent;
    this.maxCustomPoints = maxCustomPoints;
    this.isCustomRatingScale = isCustomRatingScale;
  }
}

class Answer extends BaseObject {
  constructor(
    id = null,
    modified = null,
    rfiRecipientId = null,
    textResponse = "",
    moreDetails = "",
    booleanResponse = null,
    questionId = null,
    type = null,
    documents = [],
    rating = 0
  ) {
    super(id, modified);
    this.textResponse = textResponse;
    this.moreDetails = moreDetails;
    this.booleanResponse = booleanResponse;
    this.questionId = questionId;
    this.rfiRecipientId = rfiRecipientId;
    this.type = type;
    this.documents = documents;
    this.uploadFiles = [];
    this.rating = rating;
  }

  getDisplayText() {
    if (this.type == "text") {
      return this.textResponse;
    } else {
      var result = this.textResponse;
      if (this.type == "yes-or-no") {
        result = this.booleanResponse ? "Yes" : "No";
      }
      if (this.moreDetails) result += ` - ${this.moreDetails}`;
    }
    return result;
  }

  getDocumentList() {
    if (!this.documents) return [];

    return this.documents.map((x) => {
      return { display: x.Filename, url: `/api/RfiDocument/GetById/${this.id}` };
    });
  }
}
class Owner {
  constructor(name = "") {
    this.name = name;
  }
}
class Requirement {
  constructor(id = "", chartererName = "", summary = "") {
    this.id = id;
    this.chartererName = chartererName;
    this.summary = summary;
  }
}

class RfiQuestionnaireReport {
  constructor(questionnaireReportDTO) {
    this.rfiQuestionnaire = questionnaireReportDTO ? mapQuestionnaire(questionnaireReportDTO.RfiQuestionnaire) : null;
    this.allQuestions = questionnaireReportDTO?.AllQuestions;
    this.rfiRecipientSummary = questionnaireReportDTO?.RfiRecipientSummary;
    this.allResponsesGroupedByQuestion = questionnaireReportDTO?.AllResponsesGroupedByQuestion;
    if (this.allResponsesGroupedByQuestion) {
      var allResponseKeys = Object.keys(this.allResponsesGroupedByQuestion);

      for (var i = 0; i < allResponseKeys.length; i++) {
        var thisQuestionAnswers = this.allResponsesGroupedByQuestion[allResponseKeys[i]];
        if (thisQuestionAnswers) {
          var ownerKeys = Object.keys(thisQuestionAnswers);
          for (var x = 0; x < ownerKeys.length; x++) {
            if (thisQuestionAnswers[ownerKeys[x]]) {
              thisQuestionAnswers[ownerKeys[x]] = mapAnswer(thisQuestionAnswers[ownerKeys[x]]);
            }
          }
        }
      }
    }
    this.polarChartData = questionnaireReportDTO?.PolarChartData;
    this.isCustomRatingScale;
    this.stats = {
      totalCount: questionnaireReportDTO?.TotalCount,
      noResponseCount: questionnaireReportDTO?.NoResponseCount,
      unsentCount: questionnaireReportDTO?.UnsentCount,
      inProgressCount: questionnaireReportDTO?.InProgressCount,
      completedCount: questionnaireReportDTO?.CompletedCount,
    };
  }

  getSummary() {
    if (!this.rfiQuestionnaire) return "";
    var result = `${this.rfiQuestionnaire.charterer} | `;

    if (this.rfiQuestionnaire.requirement) {
      result = this.rfiQuestionnaire.requirement.summary;
    } else {
      result += this.rfiQuestionnaire.projectDescription;
    }
    return result;
  }
}

class RfiMessage {
  constructor(content, type, created, createdBy) {
    this.content = content;
    this.created = created;
    this.createdBy = createdBy;
    this.type = type;
  }
  displayDate() {
    return toDateTimeString(this.created);
  }
}

export { Template, Question, RfiQuestionnaire, Recipient, Answer, Owner, Requirement, RfiQuestionnaireReport, RfiQuestionnaireSummary, RfiMessage };
