import { DateTime } from "luxon";

function validate(date) {
  if (!date) return false;

  let object = DateTime.fromISO(date);

  if (object.isValid && object.year > 1900) return object;

  return false;
}

function format(date, format, noDataSymbol = "-") {
  if (date instanceof DateTime) date = date.toISOString();
  else if (date instanceof Date) date = date.toISOString();

  if (validate(date)) {
    let formatted = DateTime.fromISO(date, {
      setZone: true,
    }).toFormat(format);

    if (format === "Invalid DateTime") return noDataSymbol;

    return formatted;
  }

  return noDataSymbol;
}

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const validateEmail = (email) => {
  return (
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) != null
  );
};

const parseDateYYYYMMDDFromSlashDateString = (date) => {
  if (!date) return null;

  const [day, month, year] = date.split("/");
  return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
};

const dateToDatePickerDDMMYYYY = (date) => {
  if (!date) {
    return null;
  }
  if (date.indexOf("T") > -1) {
    date = date.split("T")[0];
  }

  const [year, month, day] = date.split("-");
  return `${day}/${month}/${year}`;
};

const parseDateForPickerValue = (date) => {
  if (!date) return null;
  if (typeof date === "string" || date instanceof String) return parseDateForPickerValueFromString(date);
  return parseDateForPickerValueFromDate(date);
};
const parseDateForPickerValueFromString = (date) => {
  if (date.indexOf("T") > -1) return date.split("T")[0];
  if (date.indexOf("/") > -1) return parseDateYYYYMMDDFromSlashDateString(date);
  return date;
};

const parseDateForPickerValueFromDate = (date) => {
  return format(date, "yyyy-MM-dd");
};

const toTime = (date) => {
  return format(date, "HH:mm");
};

const toDateTimeString = (date) => {
  return format(date, "HH:mm dd/MM");
};
var stringToColour = function (str) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = "#80";
  for (var x = 0; x < 3; x++) {
    var value = (hash >> (x * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
};

function colourGradientor(p, startColor, endColor) {
  var splitStartColor = splitHexToRGB(startColor);
  var splitEndColor = splitHexToRGB(endColor);

  var diffRed = hexToDecimal(splitEndColor.red) - hexToDecimal(splitStartColor.red);
  var diffGreen = hexToDecimal(splitEndColor.green) - hexToDecimal(splitStartColor.green);
  var diffBlue = hexToDecimal(splitEndColor.blue) - hexToDecimal(splitStartColor.blue);

  diffRed = diffRed * p + hexToDecimal(splitStartColor.red);
  diffGreen = diffGreen * p + hexToDecimal(splitStartColor.green);
  diffBlue = diffBlue * p + hexToDecimal(splitStartColor.blue);

  return `${decimalToHex(Math.round(diffRed))}${decimalToHex(Math.round(diffGreen))}${decimalToHex(Math.round(diffBlue))}`;
}

function splitHexToRGB(hexColor) {
  return {
    red: hexColor.substring(0, 2),
    green: hexColor.substring(2, 4),
    blue: hexColor.substring(4, 6),
  };
}
const hexToDecimal = (hex) => parseInt(hex, 16);

const decimalToHex = (dec) => dec.toString(16);

var setTransitionalColorPalette = function (startColor, endColor, count) {
  var result = [startColor];

  for (var i = 1; i < count - 1; i++) {
    var thisColor = colourGradientor(i / count, startColor, endColor);
    result.push(thisColor);
  }
  result.push(endColor);

  return result.map((x) => `#${x}80`);
};

export {
  getBase64,
  validateEmail,
  parseDateYYYYMMDDFromSlashDateString,
  dateToDatePickerDDMMYYYY,
  toTime,
  parseDateForPickerValue,
  stringToColour,
  setTransitionalColorPalette,
  toDateTimeString,
};
