<template>
  <v-app-bar class="py-0" fixed>
    <v-container class="py-0" style="position: relative">
      <v-tabs
        align-with-title
        height="64px"
        background-color="transparent"
        :value="currentIndex"
      >
        <v-tab
          v-for="(item, i) in includedNavigationItems"
          :key="`navigation-${i}`"
          @click="navigate(item.to)"
          >{{ item.text }}</v-tab
        >
      </v-tabs>
      <v-btn
        style="position: absolute; right: 12px; top: 14px"
        text
        @click="onLogoutClick"
        >Logout <v-icon>mdi-logout</v-icon></v-btn
      >
    </v-container>
  </v-app-bar>
</template>
<script>
export default {
  name: "navigation-menu",
  props: {
    currentRoute: { type: String, default: "" },
  },
  data() {
    return {};
  },
  methods: {
    navigate(to) {
      this.$router.push({ path: to });
    },
    onLogoutClick() {
      var confirmLogout = confirm("Are you sure you want to logout?");
      if (!confirmLogout) return;
      this.$store
        .dispatch("authentication/logout")
        .then(() => this.$router.push({ path: "/login" }));
    },
  },
  computed: {
    navigationItems() {
      return this.$store.getters["layout/navItems"];
    },
    includedNavigationItems() {
      if (this.isOwner) {
        return this.navigationItems.filter((x) => x.name != "templates");
      }
      return this.navigationItems;
    },
    currentIndex() {
      return this.navigationItems.findIndex((x) => x.name == this.currentRoute);
    },
  },
};
</script>
