import Vue from "vue";
import App from "./App.vue";
import router from "@/plugins/router/router";
import vuetify from "@/plugins/vuetify/vuetify";
import store from "@/plugins/vuex/index";
import axios from "axios";
import globalMixins from "@/mixins/globalMixins.js";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

Vue.use(axios);
Vue.use(globalMixins, { store });

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
